import { Inject, Injectable } from '@angular/core';

import { Loader } from '@googlemaps/js-api-loader';
import { BehaviorSubject } from 'rxjs';
import { ICustomLocation, TranslationService } from '@ess-front/shared';

@Injectable()
export class MapService {
  isLoaded$ = new BehaviorSubject<boolean>(false);
  geoService: google.maps.Geocoder;

  constructor(
    @Inject('env') private environment: any,
    private translationService: TranslationService,
  ) {
    const language = this.translationService.getActiveLanguage();
    new Loader({ apiKey: this.environment.googleMapsApiKey, language }).importLibrary('places').then(() => {
      this.geoService = new google.maps.Geocoder();
      this.isLoaded$.next(true);
    });
  }

  /**
   * Geocoding is the process of converting addresses into geographic coordinates.
   */
  async toGeoCoords(address: string): Promise<google.maps.GeocoderResult> {
    return this.geocode({ address });
  }
  /**
   * Reverse geocoding is the process of converting geographic coordinates into a human-readable address.
   */
  async toGeoAddress(location: google.maps.LatLng): Promise<google.maps.GeocoderResult> {
    return this.geocode({ location });
  }

  toCustomLocation(location: google.maps.LatLng): ICustomLocation {
    return !!location && location instanceof google.maps.LatLng
      ? { longitude: location.lng(), latitude: location.lat() }
      : null;
  }

  fromCustomLocation(location: ICustomLocation): google.maps.LatLng {
    return !!location &&
      Object.prototype.hasOwnProperty.call(location, 'latitude') &&
      Object.prototype.hasOwnProperty.call(location, 'longitude')
      ? new google.maps.LatLng(location.latitude, location.longitude)
      : null;
  }

  /***/
  async geocode(request: google.maps.GeocoderRequest): Promise<google.maps.GeocoderResult> {
    return new Promise((resolve, reject) => {
      const callback = (places, status): void => {
        if (status === google.maps.GeocoderStatus.OK) {
          resolve(places);
        } else {
          reject(null);
        }
      };

      this.geoService.geocode(request, callback);
    });
  }
}
